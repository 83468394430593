import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

import { useExperimentStore } from '@/helpers/composables/useStores';
import type { ExperimentData } from '@/helpers/experiment-helper';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

// ?preferred_variant=SR_CALL_TO_ACTION_EXP:control
// ?preferred_variant=SR_CALL_TO_ACTION_EXP:variant_1

// ?remove_preferred_experiment=SR_CALL_TO_ACTION_EXP

export const SrCallToActionExp_Name = 'SR_CALL_TO_ACTION_EXP' as const;

export type SrCallToActionExp_Variants = ToVariants<typeof SrCallToActionExp_Variants>;

export const SrCallToActionExp_Variants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
} as const;

export default generate(SrCallToActionExp_Name, SrCallToActionExp_Variants, () => false);

export function remoteTriggerSrCallToActionExp(sponsoredAd: SponsoredAdFragment) {
	if (!sponsoredAd.campaign?.name?.toUpperCase().includes('HOLD_YOUR_BREATH_BURST2_US_SRA')) return;

	const { triggerExperiment } = useExperimentStore();

	triggerExperiment({
		name: SrCallToActionExp_Name,
		variants: Object.values(SrCallToActionExp_Variants).map(name => ({ name, weight: 1 })),
		goals: [],
	} as ExperimentData);
}
