import { deleteUser, getAuth, sendEmailVerification, signOut } from 'firebase/auth';
import Cookies from 'js-cookie';
import Vue from 'vue';

import type { LoginProvider, LoginProviderAccount } from '@/enums/login';
import type { WebLocale } from '@/enums/web-locale';
import type { SnowplowContext } from '@/helpers/tracking/providers';
import type { AuthResponse } from '@/interfaces/responses/auth';
import type { GettersConfig } from '@/interfaces/store';
import type { SubscriptionInfo } from '@/interfaces/subscriptions';
import type { OfferPresentationType, TitleObjectType } from '@/interfaces/titles';
import type { Store as AlephBetStore, StoreItem as AlephBetStoreItem } from 'alephbet';
import type { ApolloProvider } from 'vue-apollo';
import type { ActionTree, MutationTree } from 'vuex';

import { STORAGE_MIGRATION_REVISION } from '@/migrate-local-storage';

import { LoginProviderByFirebaseProviderId } from '@/enums/login';
import { OnboardingStatus } from '@/enums/onboarding-status';

import { getAxios } from '@/helpers/axios-helper';
import { saveConsents } from '@/helpers/cookiebot-helper';

import { getIdServiceVariables } from '@/helpers/id-helper';
import { getOnboardingStatus } from '@/helpers/onboarding-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { currentUser, generateToken, logout, sendCode as sendTVCode } from '@/helpers/user-helper';
import {
	DataProcessingService,
	DataProcessingServiceConsent,
	LocalDataProcessingServices,
} from '@/helpers/usercentrics-helper';

import { ReadyType } from '@/stores/ready.store';

import {
	GetNewDeviceIdInputDocument,
	GetNewDeviceIdInputMutation,
	GetNewDeviceIdInputMutationVariables,
} from '@/graphql/mutation/GetNewDeviceId.mutation';

import { findSubscriptionBy } from '@/helpers/pro-web-helper';
import { getSettings, mutateSettings } from '@/helpers/settings-helper';
import { hasUCConsentBanner } from '@/helpers/tracking';
import { hasAnyConsentsGiven } from '@/helpers/consent-helper';
import { useUserStats } from '@/helpers/composables/useUserStats';

const RECENT_SEARCH_TERMS_COUNT = 10;

export const CONSENT_PARTNERS = {
	google: { name: 'Google', short: 'G', id: '1' },
	facebook: { name: 'Facebook', short: 'FB', id: '2' },
	twitter: { name: 'Twitter', short: 'TW', id: '4' },
	tiktok: { name: 'TikTok', short: 'TT', id: '6' },
	snapchat: { name: 'Snapchat', short: 'SC', id: '8' },
	allother: { name: 'All Other Providers', short: 'AO', id: '5' },
	emails: { name: 'Email', short: 'E', id: '7' },
	terms_and_conditions: { name: 'Terms and Conditions', short: 'TC', id: '9' },
} as const;

export type Consent = keyof typeof CONSENT_PARTNERS;

export type UpdateUserInfoPayload = {
	loginProvider: LoginProvider;
	name: string;
	email: string;
	emailVerified: boolean;
};

export interface ExperimentFromUrl {
	experiment: string;
	variant?: string;
	sendSnowplowEvent?: boolean;
}

export type UserExperiment = AlephBetStoreItem & { tracking: boolean; preferred: boolean };
export type UserExperiments = {
	[name: string]: UserExperiment;
};

export enum BuyBoxFilterOptions {
	ALL = 'all',
	SUBSCRIPTION = 'flatrate',
	FREE = 'free',
	FAST_TV = 'fast',
	BUY = 'buy',
	RENT = 'rent',
	CINEMA = 'cinema',
}

export enum NewBuyBoxView {
	LIST = 'list',
	GRID = 'grid',
}

export interface UserInfo {
	email?: string;
	loginProvider?: LoginProvider;
	name?: string;
	emailVerified?: boolean;
	creationTime?: Date;
}

export enum TasteSurveyType {
	NONE = 'NONE',
	DIVERGING_TITLES = 'DIVERGING_TITLES',
	FINDER_GAME = 'FINDER_GAME',
	TV_APP_BLANK = 'TV_APP_BLANK',
	AUTO_DIGGER = 'AUTO_DIGGER',
	STATIC_DIGGER = 'STATIC_DIGGER',
	STATIC_MAPPINGS = 'STATIC_MAPPINGS',
	GLOBAL_CLICKED_CTA = 'GLOBAL_CLICKED_CTA',
}

export enum VisibleFlyoutType {
	HOME_INVITATION_BOX = 'homeInvitationBox',
	ONBOARDED_HOME = 'onboardedHome',
	FREEMIUM_BANNER = 'freemiumBanner',
}

export enum premiumPurchaseFlowState {
	AUTH_STARTED = 'AUTH_STARTED',
	AUTH_SUCCESS = 'AUTH_SUCCESS',
	CHECKOUT = 'CHECKOUT',
}

export interface UserSettings {
	hidden_timeline_specials: string[];
	jw_consents: Record<Consent, boolean | null>;
	providers: string[];
	taste_survey_completed: boolean;
	taste_survey_model_version: string;
	taste_survey_type: TasteSurveyType;
	uc_consents: { id: string; consent: boolean }[] | null;
}

export interface UserSearches {
	recentSearchTerms: string[];
	recentlyVisitedTitleIds: string[];
}

export interface DiscoverySettings {
	continueWatchingHintDismissed: boolean;
}

// ExperimentSettings is used to define the `experiments` key in the local storage
export interface ExperimentSettings {
	// placeholderSetting: PlaceholderType;
}

export interface VisibleFlyouts {
	[VisibleFlyoutType.HOME_INVITATION_BOX]: boolean;
	[VisibleFlyoutType.ONBOARDED_HOME]: boolean;
	[VisibleFlyoutType.FREEMIUM_BANNER]: boolean;
	[flyoutName: string]: boolean;
}

export type UserState = {
	accessToken: string | undefined; // used to send for certain routes with authorization token
	alephbet: AlephBetStore;
	discoverySettings: DiscoverySettings;
	enableProvidersFilter: boolean;
	hasCancelledPremium: boolean;
	hideSeenDislikedTitles: boolean;
	info: UserInfo;
	isPremium: boolean;
	isVerificationEmailSent: boolean;
	jwId: string | undefined;
	jwLoginId: string | undefined;
	lastUsedLoginProvider: string | undefined;
	loggedInProviders: LoginProvider[]; // list of provider names the user is authenticated with
	loginProgress: boolean;
	onboardingStatus: OnboardingStatus | null;
	persistedOverriddenLanguage: string | null;
	persistedWebLocale: WebLocale | null;
	permanentAudiencesSubgenres: string[];
	preferredExperiments: { [experiment: string]: { variant: string; sendSnowplowEvent: boolean } };
	preferences: {
		buyboxPresentationType: OfferPresentationType | null;
	};
	newBuyboxFilterType: BuyBoxFilterOptions;
	newBuyboxView: NewBuyBoxView;
	newBuyboxTooltipAcknowledged: boolean;
	displayHomeSportsModule: boolean;
	premiumPurchaseFlowState: premiumPurchaseFlowState | null;
	searches: UserSearches;
	seenSurveys: Record<string, string[]>;
	settings: UserSettings; // user settings for non- and logged-in users
	storageMigratedRevision: number; // used to determine when to do migration upon startup
	lastTimeMobileAppBannerWasClosedTS: number; // use to determine when mobile app banner was closed last time
	lastTimeListsOnboardingWasShownTS: number; // use to determine when onboarding was shown last time
	// Start COOKIE_BANNER_EXPERIMENT
	subscriptionInfo?: SubscriptionInfo;
	timeZone: string | undefined;
	visibleFlyouts: VisibleFlyouts;
	// we need a way to reactively store all the DPS (data processing services) from Usercentrics.
	// then we can build an eligible DPS list by combining Usercentrics DPS and our usercentrics-helper.ts DPS.
	// with this we won't break any web app releases by changing them.
	usercentricsDPS: { id: string; name: string }[] | null;
	webBrazeEnabled: boolean;
	isUserFirstVisit: boolean | null; // true: default value, null: on first visit, false: user has visited before
};

export interface UserGetters {
	strictlyAllConsentsGranted: boolean;
	bestEffortId: string | undefined;
	buyboxPresentationType: UserState['preferences']['buyboxPresentationType'];
	newBuyboxFilterType: BuyBoxFilterOptions;
	newBuyboxView: NewBuyBoxView;
	newBuyboxTooltipAcknowledged: boolean;
	displayHomeSportsModule: boolean;
	consents: Record<Consent, boolean | null> | null; // deprecated in favor if DPSconsents
	dpsConsents: Record<DataProcessingService, boolean | null> | null; // data processing consents (usercentrics)
	getPermanentAudiencesSubgenres: string[];
	hasCancelledPremium: boolean;
	hasCompletedSurvey: boolean;
	isFlyoutVisible: (name: string) => boolean;
	isHideSeenDislikedTitlesEnabled: boolean;
	isLastUsedLoginProvider: (providerName?: string) => boolean;
	isLoggedIn: boolean;
	isPremium: boolean;
	isRedirectToHomeBannerVisible: boolean;
	isUserOnboarded: boolean;
	isWebBrazeEnabled: boolean;
	jwLoginId: string | undefined;
	settingsProvider: string[];
	subscriptionInfo: SubscriptionInfo | null;
	userExperiments: UserExperiments;
	userInfo: UserInfo;
	loggedInProviders: LoginProvider[];
	userSearches: UserState['searches'];
}
export type UserGettersConfig = GettersConfig<UserState, UserGetters>;

export const DEFAULT_USERSETTINGS = {
	// fill create map with consent partners valued with `null`
	jw_consents: (Object.keys(CONSENT_PARTNERS) as Consent[]).reduce((root, key) => {
		root[key] = null;
		return root;
	}, {} as Record<Consent, boolean | null>),
	providers: [] as string[],
	hidden_timeline_specials: [] as string[],
	taste_survey_completed: false,
	taste_survey_type: TasteSurveyType.AUTO_DIGGER,
	taste_survey_model_version: '',
	uc_consents: null,
} as UserSettings;

export const getInitialUserState = (): UserState => ({
	accessToken: undefined,
	alephbet: {},
	discoverySettings: {
		continueWatchingHintDismissed: false,
	} as DiscoverySettings,
	enableProvidersFilter: false,
	hasCancelledPremium: false,
	hideSeenDislikedTitles: false,
	info: {},
	isPremium: false,
	isVerificationEmailSent: false,
	jwId: undefined,
	jwLoginId: undefined,
	lastUsedLoginProvider: undefined,
	loggedInProviders: [],
	loginProgress: false,
	onboardingStatus: null,
	persistedOverriddenLanguage: null,
	persistedWebLocale: null,
	permanentAudiencesSubgenres: [] as string[],
	preferredExperiments: {},
	preferences: {
		buyboxPresentationType: null,
	},
	newBuyboxFilterType: BuyBoxFilterOptions.ALL,
	newBuyboxView: NewBuyBoxView.LIST,
	newBuyboxTooltipAcknowledged: false,
	displayHomeSportsModule: true,
	premiumPurchaseFlowState: null,
	searches: {
		recentSearchTerms: [],
		recentlyVisitedTitleIds: [],
	},
	seenSurveys: {},
	settings: DEFAULT_USERSETTINGS,
	storageMigratedRevision: STORAGE_MIGRATION_REVISION,
	lastTimeMobileAppBannerWasClosedTS: 0,
	lastTimeListsOnboardingWasShownTS: 0,
	timeZone: undefined,
	usercentricsDPS: null,
	visibleFlyouts: {
		[VisibleFlyoutType.HOME_INVITATION_BOX]: true,
		[VisibleFlyoutType.ONBOARDED_HOME]: true as boolean,
		[VisibleFlyoutType.FREEMIUM_BANNER]: true,
	},
	webBrazeEnabled: false,
	isUserFirstVisit: true,
});

const defaultSubscriptionInfo: SubscriptionInfo = {
	id: '',
	priceId: '',
	price: 2.49,
	type: 'regular',
	timeframe: 'monthly',
};

export type UserStore = {
	state: UserState;
	getters: UserGettersConfig;
};

// it needs to be a function that will later be called.
const state = getInitialUserState;

// GETTERS
// -------
const getters: UserGettersConfig = {
	userExperiments: (state, getters) => {
		const userExperiments = {} as UserExperiments;

		for (const name in state.preferredExperiments) {
			const { variant, sendSnowplowEvent: tracking } = state.preferredExperiments[name];
			userExperiments[name] = {
				in_sample: true,
				tracking,
				variant,
				preferred: true,
			};
		}

		for (const key in state.alephbet) {
			const [name, type] = key.split(':') as [string, keyof AlephBetStoreItem];
			if (!state.preferredExperiments[name]) {
				if (!userExperiments[name]) {
					userExperiments[name] = {
						tracking: true,
						preferred: false,
					} as UserExperiment;
				}
				(userExperiments[name][type] as AlephBetStoreItem[keyof AlephBetStoreItem]) = state.alephbet[key];
			}
		}
		return userExperiments;
	},
	userInfo: state => {
		return !state.accessToken ? {} : state.info;
	},
	loggedInProviders: state => {
		return state.loggedInProviders;
	},
	buyboxPresentationType: state => state.preferences.buyboxPresentationType,
	newBuyboxFilterType: state => state.newBuyboxFilterType,
	newBuyboxView: state => state.newBuyboxView,
	newBuyboxTooltipAcknowledged: state => state.newBuyboxTooltipAcknowledged,
	displayHomeSportsModule: state => state.displayHomeSportsModule,
	getPermanentAudiencesSubgenres(state): string[] {
		return state.permanentAudiencesSubgenres;
	},
	isLoggedIn: state => state.loggedInProviders.length > 0,
	isLastUsedLoginProvider:
		state =>
		(providerName?: string): boolean => {
			return state.lastUsedLoginProvider === providerName;
		},
	isFlyoutVisible:
		state =>
		(name: string): boolean => {
			return state.visibleFlyouts[`${name}`];
		},
	bestEffortId: state => state.jwLoginId || state.jwId,
	jwLoginId: state => {
		// In some situations (purchases, JW Pro), we explicitly need a login ID to confirm
		// that the user has finished the auth flow and is logged in.
		return state.jwLoginId;
	},
	consents: state => {
		// TODO Store should produce computed consents. Right now we assume some consents could be missing (see ConsentModal.vue)
		return state.settings?.jw_consents || {};
	},
	dpsConsents: state => {
		const explicitlyConsented = (state.settings?.uc_consents || [])
			.filter(item => item.consent)
			.map(item => item.id);
		const excplicitlyRejected = (state.settings?.uc_consents || [])
			.filter(item => !item.consent)
			.map(item => item.id);

		return (Object.keys(LocalDataProcessingServices) as DataProcessingService[]).reduce((root, serviceKey) => {
			const service = LocalDataProcessingServices[serviceKey];
			let consentValue = null;
			if (explicitlyConsented.includes(service.id)) {
				// user HAS consented to this service
				consentValue = true;
			} else if (excplicitlyRejected.includes(service.id)) {
				// user HAS rejected consent to this service
				consentValue = false;
			} else {
				// user HAS NOT consented nor rejected to this service
				consentValue = null;
			}
			root![serviceKey] = consentValue;
			return root;
		}, {} as UserGetters['dpsConsents']);
	},

	// in cases like 'injecting external trackers' we need to have the user's full consent to ALL partners that we currently have set up as DPS.
	// so we can be as data privacy compliant as possible.
	//
	// we differentiate between two groups:
	// - UC countries: all partner consents must have `consent: true`
	// - non-UC countries: the `jw_consents.allother` value must be `true`
	strictlyAllConsentsGranted: (state, getters, rootState) => {
		if (hasUCConsentBanner(rootState.language.webLocale)) {
			const { uc_consents } = state.settings;
			const totalDPS = state.usercentricsDPS ? state.usercentricsDPS.length : 0;
			// totalDPS is synced with UserCentrics, so it will be 0 in the beginning.
			// make sure they are loaded and not empty at 0.
			return totalDPS > 0 && state.usercentricsDPS?.length === uc_consents?.length;
		} else {
			return hasAnyConsentsGiven(getters.consents);
		}
	},

	userSearches: state => {
		return {
			recentSearchTerms: state.searches.recentSearchTerms.slice(0, 10),
			recentlyVisitedTitleIds: state.searches.recentlyVisitedTitleIds.slice(0, 10),
		};
	},
	hasCompletedSurvey: state => state.settings.taste_survey_completed || false,
	isRedirectToHomeBannerVisible: state => {
		const onboardingStatus = [
			OnboardingStatus.GLOBAL_CLICKED_CTA,
			OnboardingStatus.USER,
			OnboardingStatus.ONBOARDED,
		];

		return (
			!state.settings.taste_survey_completed &&
			state.settings.taste_survey_type !== TasteSurveyType.GLOBAL_CLICKED_CTA &&
			!onboardingStatus.includes(state.onboardingStatus as OnboardingStatus)
		);
	},
	isUserOnboarded: state => {
		const status = [
			OnboardingStatus.ONBOARDED,
			OnboardingStatus.USER,
			OnboardingStatus.GLOBAL_CLICKED_CTA,
			OnboardingStatus.SIGNED_UP,
		];
		return state.onboardingStatus != null && status.includes(state.onboardingStatus);
	},
	isHideSeenDislikedTitlesEnabled: state => {
		return state.hideSeenDislikedTitles;
	},
	isPremium: state => {
		return state.isPremium;
	},

	hasCancelledPremium: state => {
		return state.hasCancelledPremium;
	},

	settingsProvider: state => {
		return state.settings.providers ?? [];
	},
	isWebBrazeEnabled: state => {
		return state.webBrazeEnabled;
	},
	// applicationsUsed: state => state.settings.applications || [],

	subscriptionInfo: state => {
		return state.subscriptionInfo || defaultSubscriptionInfo;
	},
};

// ACTIONS
// -------
const actions: ActionTree<UserState, any> = {
	applyExperimentFromUrl: async (
		{ dispatch },
		{
			add,
			remove,
		}: {
			add: ExperimentFromUrl;
			remove: ExperimentFromUrl;
		}
	) => {
		const promises: Promise<any>[] = [];
		add && promises.push(dispatch('addPreferredExperiment', add));
		remove && promises.push(dispatch('removePreferredExperiment', remove));
		return await Promise.all(promises);
	},
	addPreferredExperiment: async (
		{ commit, state },
		{ experiment, variant, sendSnowplowEvent }: ExperimentFromUrl
	) => {
		if (!variant) return;

		let preferredExperiments: UserState['preferredExperiments'] | null = null;
		const current = state.preferredExperiments[experiment];
		sendSnowplowEvent = sendSnowplowEvent || false;

		if (!current || current.variant !== variant || current.sendSnowplowEvent !== sendSnowplowEvent) {
			preferredExperiments = { ...state.preferredExperiments };
			preferredExperiments[experiment] = { variant, sendSnowplowEvent };
		}

		if (preferredExperiments) {
			commit('REMOVE_ALEPHBET_BY_NAME', experiment);
			commit('SET_PREFERRED_EXPERIMENTS', preferredExperiments);
		}
	},

	removePreferredExperiment: ({ commit, state }, { experiment }: ExperimentFromUrl) => {
		let preferredExperiments: UserState['preferredExperiments'] | null = null;
		if (state.preferredExperiments[experiment]) {
			preferredExperiments = { ...state.preferredExperiments };
			delete preferredExperiments[experiment];
		}

		commit('REMOVE_ALEPHBET_BY_NAME', experiment);
		commit('SET_PREFERRED_EXPERIMENTS', preferredExperiments ?? {});
	},

	fetchJwId: async ({ dispatch, state }, apolloProvider: ApolloProvider) => {
		try {
			const variables = await getIdServiceVariables(state.jwId || null, state.settings?.jw_consents || {});

			const results = await apolloProvider.defaultClient.mutate<
				GetNewDeviceIdInputMutation,
				GetNewDeviceIdInputMutationVariables
			>({
				mutation: GetNewDeviceIdInputDocument,
				variables: {
					input: {
						doNotTrack: variables.dnt,
						ids: [variables.ids],
					},
				},
			});

			if (results?.data?.getNewDeviceId.deviceId) {
				dispatch('setJwId', results?.data?.getNewDeviceId.deviceId);
			} else {
				TrackingHelper.trackEvent('idservice', {
					action: 'lookup',
					label: 'failed',
					nonInteraction: true,
				});
				throw 'no valid ID received';
			}
		} catch (error: any) {
			// Webapp can init, but better keep tracking as this is still a limited app experience (no settings)
			const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
			captureMessageForSentry('User Init Issue:', { error, where: '[user.store.ts] fetchJwId' }, 'error');
		}
	},

	setJwId: async ({ commit }, jwId: string) => {
		commit('SET_JW_ID', jwId);
		commit('ready/SET_READY', ReadyType.JW_ID, { root: true });
	},

	setBuyboxPresentationType(
		{ commit },
		payload: { presentationType: OfferPresentationType | null; additionalContexts?: SnowplowContext[] }
	) {
		const { presentationType, additionalContexts = [] } = payload;
		commit('SET_PREFERENCES_VALUE', presentationType);

		TrackingHelper.trackEvent(
			'presentationtype_change',
			{ action: presentationType ?? undefined },
			additionalContexts
		);
	},

	setNewBuyboxFilterType({ commit }, filterType: BuyBoxFilterOptions) {
		commit('SET_NEW_BUYBOX_FILTER_VALUE', filterType);
		// TrackingHelper.trackEvent('presentationtype_change', { action: filterType ?? undefined }, additionalContexts);
	},

	setNewBuyboxView({ commit }, viewType: NewBuyBoxView) {
		commit('SET_NEW_BUYBOX_VIEW', viewType);
	},

	setNewBuyboxTooltipAcknowledged({ commit }) {
		commit('SET_NEW_BUYBOX_TOOLTIP_ACKNOWLEDGED');
	},
	hideHomeSportsModule({ commit }) {
		commit('SET_DISPLAY_HOME_SPORTS_MODULE');
	},

	/**
	 * Finds out if is login or signup
	 *
	 * @throws Error when user is not signed up yet
	 */
	verifyRegistration: async (): Promise<void> => {
		const user = await currentUser();

		if (!user) return;
		const tokenResult = await user.getIdTokenResult();

		if (tokenResult.claims.registration_error) throw `registration error: ${tokenResult.claims.registration_error}`;
		if (!tokenResult.claims.jw_login_id) throw 'not yet registered internally';
	},

	refreshToken: async ({ commit, dispatch }): Promise<string | undefined> => {
		try {
			const token = await generateToken(false);

			if (!token) {
				throw new Error('Error getting refresh token');
			}

			commit('SET_AUTH_TOKEN', token);
			return token;
		} catch (error) {
			// Logout if we can't refresh the token.
			const err: any = error;

			try {
				await dispatch('logout');
			} catch (error) {
				console.error('[user.store.ts] refreshToken: Error logging out.');
			}

			const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
			captureMessageForSentry('Refresh token Issue:', { err, where: '[user.store.ts] refreshToken' }, 'error');
		}
	},

	registerInternally: async ({ commit, dispatch, state, getters, rootGetters }, isBackgroundTask: boolean) => {
		if (getters.isLoggedIn) return;
		if (!isBackgroundTask) commit('SET_LOGIN_PROGRESS', true);

		const user = await currentUser();

		if (!user) {
			throw '[Firebase] internal registration failed: user not found';
		}

		// Get the firebase token, at this point we don't have the jw_login_id as part of claims
		const tokenResult = await user.getIdTokenResult(true);
		if (!tokenResult) {
			throw 'internal registration failed';
		}
		const { token } = tokenResult;

		try {
			await getAxios().post(
				`${JW_CONFIG.IDENTITY_URL}/register`,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + token,
						// @ts-ignore
						['DEVICE-ID']: state.jwId,
					},
				}
			);

			// After registration, get the token result again, this should now contain the jw_login_id in claims.
			const newTokenResult = await user.getIdTokenResult(true);
			if (!newTokenResult || !newTokenResult.claims.jw_login_id) {
				throw 'internal registration failed';
			}

			await sendEmailVerification(user, {
				url: location.toString(),
			});
			commit('SET_AUTH_DATA', {
				connected_accounts: user.providerData.map((p: LoginProviderAccount) => {
					return {
						email: p!.email,
						login_provider: LoginProviderByFirebaseProviderId[p!.providerId],
					};
				}),
				device_id: state.jwId,
				login_id: newTokenResult.claims.jw_login_id,
				token: newTokenResult.token,
				loginProvider: LoginProviderByFirebaseProviderId[tokenResult.signInProvider as string],
			});

			await dispatch('loadSettings');
			await dispatch('saveSettings', { providers: rootGetters['constant/preselectedProviders'] });
			await dispatch('loadInfo');

			if (state.premiumPurchaseFlowState == premiumPurchaseFlowState.AUTH_STARTED) {
				// set state to continue purchase flow without user interaction
				commit('SET_PREMIUM_PURCHASE_FLOW_STATE', premiumPurchaseFlowState.AUTH_SUCCESS);
			}
			Vue.$jw.ready?.setReady(ReadyType.LOGIN_FINISHED);
		} catch (err) {
			throw err;
		} finally {
			commit('SET_LOGIN_PROGRESS', false);
		}
	},
	signInInternally: async ({ commit, dispatch, state }) => {
		commit('SET_LOGIN_PROGRESS', true);

		const user = await currentUser();

		if (!user) {
			commit('SET_LOGIN_PROGRESS', false);
			throw 'no firebase user';
		}
		try {
			const tokenResult = await user.getIdTokenResult(true);
			if (!tokenResult || !tokenResult.claims.jw_login_id) {
				throw 'need JW login ID for signing in internally';
			}

			await getAxios().post(
				`${JW_CONFIG.IDENTITY_URL}/sign_in`,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + tokenResult.token,
						// @ts-ignore
						['DEVICE-ID']: state.jwId,
					},
				}
			);

			commit('SET_AUTH_DATA', {
				connected_accounts: user.providerData.map((p: LoginProviderAccount) => {
					return {
						email: p!.email,
						login_provider: LoginProviderByFirebaseProviderId[p!.providerId],
					};
				}),
				device_id: state.jwId,
				login_id: tokenResult.claims.jw_login_id,
				token: tokenResult.token,
				loginProvider: LoginProviderByFirebaseProviderId[tokenResult.signInProvider as string],
			});

			// update settings
			await dispatch('loadSettings');

			// only MOBILE
			const { updatePremiumStatus } = await import('@/helpers/iap-helper');
			await updatePremiumStatus();
			await dispatch('loadInfo');
			// if sign-in/up was part of premium purchase flow
			if (state.premiumPurchaseFlowState == premiumPurchaseFlowState.AUTH_STARTED) {
				if (state.isPremium) {
					// user already has Premium, abort purchase flow
					commit('SET_PREMIUM_PURCHASE_FLOW_STATE', null);
				} else {
					// set state to continue purchase flow without user interaction
					commit('SET_PREMIUM_PURCHASE_FLOW_STATE', premiumPurchaseFlowState.AUTH_SUCCESS);
				}
			}

			Vue.$jw.ready?.setReady(ReadyType.LOGIN_FINISHED);
		} catch (err) {
			throw err;
		} finally {
			commit('SET_LOGIN_PROGRESS', false);
		}
	},

	/**
	 * Logout for all providers
	 */
	logout: async ({ dispatch, commit, state, rootState, rootGetters }, trackLogout = false) => {
		// Call signout to register in the consumer api that the user has signed out
		if (trackLogout) {
			await logout(state.accessToken, state.jwId);
		}

		try {
			await signOut(getAuth());
		} catch (err) {
			console.error('[Firebase] Signout error: ', err);
		}

		commit('LOGOUT');

		await dispatch('setPremiumStatus', false);
		await dispatch('setSubscriptionInfo', null);
		await dispatch('loadSettings');

		const locale = rootState.language.webLocale;
		const newOnboardingStatus = getOnboardingStatus(
			locale,
			state.settings?.taste_survey_completed,
			false,
			rootState.routing.activeRoute?.path,
			state.settings?.taste_survey_type
		);
		// To reset onboarding status
		// Need to check if is loggedIn to avoid onboardingStatus reset
		// in case of failed login attempt (calls logout in that case)
		// update settings

		await dispatch('saveOnboardingStatus', newOnboardingStatus);
		await dispatch('saveSettings', { providers: rootGetters['constant/preselectedProviders'] });
	},

	sendCode: ({ state }, code: string) => {
		if (state.accessToken) {
			return sendTVCode(code, state.accessToken);
		}
	},

	hideMobileBanner({ commit }) {
		commit('SET_MOBILE_BANNER_HIDDEN', true);

		TrackingHelper.trackEvent('promotionbanner', {
			action: 'hide_click',
		});
	},

	// @deprecated: use saveDPSConsents for newer consent management
	saveConsents({ dispatch }, consents: Record<Consent, boolean>) {
		dispatch('saveSettings', { jw_consents: consents });
		TrackingHelper.trackConsent(consents);
		TrackingHelper.trackEvent('consent', {
			action: 'saved',
			nonInteraction: true,
		});
		saveConsents(consents);
		console.log('@todo replace with UsercentricsHelper.saveConsents(consents)');
	},

	updateAgreedToConsent({ commit }) {
		commit('SET_AGREED_TO_CONSENT');
	},
	/**
	 * we only locally save DPS consents that either:
	 * - have been answered explicitly
	 * - are true (usually being 'essential')
	 *
	 * note: it's possible that `usercentricsDpsConsents` has newer DPS that the local DPS (usercentrics-helper@LocalDataProcessingServices) don't have.
	 * in the getters `dpsConsents` we ignore DPS (either from UC or local) that aren't present in both sets.
	 */
	saveDPSConsents({ dispatch, state }, usercentricsDpsConsents: DataProcessingServiceConsent[]) {
		const explicitConsents = usercentricsDpsConsents.filter(
			consent => consent.hasAnswered || consent.status === true
		);
		const uc_consents = explicitConsents.map(item => ({ id: item.id, consent: item.status }));
		// we want to prevent saving the uc_consents if they haven't changed.
		const getStringifiedConsent = (consents: UserSettings['uc_consents']) =>
			JSON.stringify([...(consents || [])].sort((a, b) => b.id.localeCompare(a.id)));
		if (getStringifiedConsent(state.settings.uc_consents) !== getStringifiedConsent(uc_consents)) {
			dispatch('saveSettings', { uc_consents });
		}
	},

	async loadSettings({ state, commit, rootGetters }) {
		await Vue.$jw.ready?.waitFor(ReadyType.JW_ID);
		if (!state.jwId) return false;

		const accessToken = state.accessToken;
		const locale = rootGetters['language/locale'];
		const settings = await getSettings(state.jwId, locale, accessToken);
		commit('SET_SETTINGS', settings);
		commit('ready/SET_READY', ReadyType.USER_SETTINGS_LOADED, { root: true });
	},

	async loadInfo({ state, commit }) {
		if (!state.accessToken) {
			commit('SET_USER_INFO', {});
			return;
		}

		const user = await currentUser();

		const info = {
			name: user?.displayName || '',
			email: user?.email || '',
			loginProvider: state.info.loginProvider,
			emailVerified: user?.emailVerified,
			creationTime: user?.metadata?.creationTime,
		};
		commit('SET_USER_INFO', info);
	},

	setLoginProviders({ commit }, loginProviders: LoginProvider[]) {
		commit('SET_LOGIN_PROVIDERS', loginProviders);
	},

	updateUserInfo({ commit }, userInfoPayload: UpdateUserInfoPayload) {
		commit('UPDATE_USER_INFO', userInfoPayload);
	},

	async deleteAccountPermanently({ dispatch }) {
		const user = await currentUser();
		if (!user) throw 'not logged in to Firebase';

		try {
			await deleteUser(user);
			await dispatch('logout');
		} catch (e) {
			const err: any = e;
			if (err.code !== 'auth/requires-recent-login') {
				const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
				captureMessageForSentry(
					'Error deleting user account:',
					{ error: err, where: '[user.store.ts] deleteAccountPermanently' },
					'error'
				);
			}
			throw e;
		}
	},

	async saveSettings({ commit, state, rootGetters }, partialSettings: Partial<UserSettings> = {}) {
		const locale = rootGetters['language/locale'];
		const settings = await mutateSettings(state.jwId, state.accessToken, locale, state.settings, partialSettings);
		commit('SET_SETTINGS', settings);
	},

	saveOnboardingStatus({ commit }, status: OnboardingStatus) {
		commit('SAVE_ONBOARDING_STATUS', status);
	},

	savePersistedOverriddenLanguage({ commit }, language: string) {
		commit('SET_PERSISTED_OVERRIDDEN_LANGUAGE', language);
	},

	saveSearchTerm({ commit }, searchTerm: string) {
		commit('SAVE_SEARCH_TERM', searchTerm);
	},
	saveVisitedTitleId({ commit }, visitedTitleId: string) {
		commit('SAVE_VISITED_TITLE_ID', visitedTitleId);
	},
	clearSearchHistory({ commit }) {
		commit('CLEAR_SEARCH_HISTORY');
	},
	hideFlyout({ commit }, flyoutName: VisibleFlyoutType | string) {
		commit('HIDE_FLYOUT', flyoutName);
	},
	async setPremiumStatus({ commit, dispatch, getters }, value: boolean) {
		if (!value && getters.isPremium) {
			await dispatch('filter/resetPremiumFilters', {}, { root: true });
			await dispatch('setHideSeenDislikedTitles', false);
		}
		commit('SET_IS_PREMIUM', value);
	},
	async setSubscriptionInfo({ commit }, subscriptionId: string) {
		const subscriptionInfo = findSubscriptionBy('id', subscriptionId);
		commit('SET_SUBSCRIPTION_INFO', subscriptionInfo);
	},
	setPremiumCancellationStatus({ commit }, value: boolean) {
		commit('SET_HAS_CANCELLED_PREMIUM', value);
	},
	setPremiumPurchaseFlowState({ commit }, value: premiumPurchaseFlowState | null) {
		commit('SET_PREMIUM_PURCHASE_FLOW_STATE', value);
	},
	setHideSeenDislikedTitles({ commit }, value: boolean) {
		commit('SET_HIDE_SEEN_DISLIKE_TITLES', value);
	},
	setTimeZone({ commit }, value: string) {
		commit('SET_TIME_ZONE', value);
	},
	setIsVerificationEmailSent({ commit }, isVerificationEmailSent: boolean) {
		commit('SET_IS_VERIFICATION_EMAIL_SENT', isVerificationEmailSent);
	},
	setLastTimeMobileAppBannerWasClosedTS({ commit }, timeStamp: number) {
		commit('SET_LAST_TIME_MOBILE_APP_BANNER_WAS_CLOSED_TS', timeStamp);
	},
	setLastTimeListOnboardingWasShownTS({ commit }, timeStamp: number) {
		commit('SET_LAST_TIME_LIST_ONBOARDING_WAS_SHOWN_TS', timeStamp);
	},
	setWebBrazeEnabled: ({ commit }, isWebBrazeEnabled: boolean) => {
		commit('SET_WEB_BRAZE_ENABLED', isWebBrazeEnabled);
	},
	hideConsentOverlay: ({ commit }) => {
		commit('HIDE_CONSENT_OVERLAY');
	},
	updateUserVisitStatus: async ({ commit, state }) => {
		if (state.isUserFirstVisit === true) {
			commit('SET_USER_FIRST_VISIT', null);
		} else if (state.isUserFirstVisit === null) {
			commit('SET_USER_FIRST_VISIT', false);
		}
	},
	setPermanentAudiencesSubgenres({ commit }, audiences: string[]) {
		commit('SET_PERMANENT_AUDIENCES_SUBGENRES', audiences);
		TrackingHelper.refreshPermanentAudiencesSubgenres(audiences);
	},
	resetPermanentAudiencesSubgenres({ commit }) {
		commit('SET_PERMANENT_AUDIENCES_SUBGENRES', []);
	},
};

// MUTATIONS
// -------
const mutations: MutationTree<UserState> = {
	SET_JW_ID: (state, jwId: string) => {
		Vue.set(state, 'jwId', jwId);
	},

	ADD_SEEN_SURVEY(
		state,
		{ surveyId, objectType, objectId }: { surveyId: number; objectType?: TitleObjectType; objectId?: number }
	) {
		if (!(surveyId in state.seenSurveys)) {
			state.seenSurveys[surveyId] = [];
		}
		if (objectType && objectId) {
			const id = `${objectType}-${objectId}`;
			if (state.seenSurveys[surveyId].indexOf(id) === -1) {
				state.seenSurveys[surveyId].push(id);
			}
		}
	},

	SET_PREFERENCES_VALUE(state, buyboxPresentationType: OfferPresentationType | null) {
		state.preferences.buyboxPresentationType = buyboxPresentationType;
	},

	SET_NEW_BUYBOX_FILTER_VALUE(state, filterType: BuyBoxFilterOptions) {
		state.newBuyboxFilterType = filterType;
	},

	SET_NEW_BUYBOX_VIEW(state, viewType: NewBuyBoxView) {
		state.newBuyboxView = viewType;
	},

	SET_NEW_BUYBOX_TOOLTIP_ACKNOWLEDGED(state) {
		state.newBuyboxTooltipAcknowledged = true;
	},
	SET_DISPLAY_HOME_SPORTS_MODULE(state) {
		state.displayHomeSportsModule = false;
	},

	SET_LOGIN_PROGRESS: (state, value: boolean) => {
		if (!value) useUserStats().refetchUserStats();
		state.loginProgress = value;
	},

	SET_AUTH_DATA: (state, authData: AuthResponse) => {
		if (authData.connected_accounts) {
			const loggedInProviders: string[] = [];
			for (let i = 0; i < authData.connected_accounts.length; i++) {
				// @ts-ignore
				const { email, login_provider, is_entry_point } = authData.connected_accounts[i];

				if (is_entry_point) {
					Vue.set(state.info, 'email', email);
					Vue.set(state.info, 'loginProvider', login_provider);
				}
				loggedInProviders.push(login_provider);
			}
			Vue.set(state, 'loggedInProviders', loggedInProviders);
		} else {
			if (authData.connected_providers.length === 1) {
				Vue.set(state.info, 'loginProvider', authData.connected_providers[0]);
			}
			Vue.set(state, 'loggedInProviders', authData.connected_providers);
		}
		state.info.loginProvider = authData.loginProvider;

		// Cookies
		Cookies.remove('access_token');
		Cookies.set('access_token', authData.token, {
			expires: 365,
			secure: true,
			sameSite: 'strict',
		});

		Vue.set(state, 'jwLoginId', authData.login_id);
		Vue.set(state, 'accessToken', authData.token);
	},
	SET_AUTH_TOKEN: (state, token: string) => {
		// Cookies
		Cookies.remove('access_token');
		Cookies.set('access_token', token, {
			expires: 365,
			secure: true,
			sameSite: 'strict',
		});

		state.accessToken = token;
	},

	SET_USER_NAME(state, name) {
		Vue.set(state.info, 'name', name);
	},

	SET_USER_INFO(state, info) {
		Vue.set(state, 'info', info);
	},

	SET_SETTINGS(state, settings) {
		Vue.set(state, 'settings', settings);
	},

	LOGOUT: state => {
		// Cookies
		Cookies.remove('access_token');

		Vue.delete(state, 'jwLoginId');
		Vue.delete(state, 'accessToken');
		Vue.set(state, 'info', {});
		const lastLoginProvider: string = state.loggedInProviders[0] || 'justwatch';
		Vue.set(state, 'lastUsedLoginProvider', lastLoginProvider);
		Vue.set(state, 'loggedInProviders', []);
		useUserStats().refetchUserStats();
	},

	SET_LOGIN_PROVIDERS: (state, loginProviders: LoginProvider[]) => {
		state.loggedInProviders = loginProviders;
	},

	UPDATE_USER_INFO: (state, userInfo: UpdateUserInfoPayload) => {
		state.info = {
			...state.info,
			...userInfo,
		};
	},

	HIDE_FLYOUT: (state, name: string) => {
		state.visibleFlyouts[`${name}`] = false;
	},

	SET_ALEPHBET(state, alephbet: any) {
		state.alephbet = alephbet;
	},

	SET_ALEPHBET_WITH_KEY_VALUE(state, { key, value }) {
		state.alephbet = { ...state.alephbet, [key]: value };
	},

	REMOVE_ALEPHBET_BY_NAME(state, name: string) {
		let alephbet = null;
		for (const key in state.alephbet) {
			const remove = key.includes(name);
			if (!alephbet && remove) {
				alephbet = { ...state.alephbet };
			}

			if (alephbet && remove) {
				delete alephbet[key];
			}
		}

		alephbet && Vue.set(state, 'alephbet', alephbet);
	},

	SET_PREFERRED_EXPERIMENTS(state, preferredExperiments: UserState['preferredExperiments']) {
		Vue.set(state, 'preferredExperiments', preferredExperiments);
	},

	SET_DISCOVERY_SETTINGS(state, discoverySettings: DiscoverySettings) {
		state.discoverySettings = discoverySettings;
	},

	SAVE_ONBOARDING_STATUS: (state, status) => {
		state.onboardingStatus = status;
	},
	SET_PERSISTED_OVERRIDDEN_LANGUAGE: (state, language) => {
		state.persistedOverriddenLanguage = language;
	},
	SET_PERSISTED_WEB_LOCALE: (state, webLocale) => {
		state.persistedWebLocale = webLocale;
	},
	SAVE_SEARCH_TERM: (state, searchTerm) => {
		let { recentSearchTerms } = state.searches;
		if (!recentSearchTerms.includes(searchTerm)) {
			// recentSearchTerms = [searchTerm, ...recentSearchTerms];
			recentSearchTerms = [searchTerm].concat(recentSearchTerms);
		} else {
			const index = recentSearchTerms.findIndex(term => term === searchTerm);
			const term = recentSearchTerms[index];
			recentSearchTerms.splice(0, 0, term);
			recentSearchTerms.splice(index + 1, 1);
		}

		// limit stored search terms to 10 to avoid store explosion
		state.searches.recentSearchTerms = recentSearchTerms.slice(0, RECENT_SEARCH_TERMS_COUNT);
	},
	SAVE_VISITED_TITLE_ID: (state, visitedTitleId: string) => {
		let { recentlyVisitedTitleIds } = state.searches;
		const containsTitle: boolean = recentlyVisitedTitleIds.some((elem: string) => elem === visitedTitleId);

		if (!containsTitle) {
			recentlyVisitedTitleIds = [visitedTitleId].concat(recentlyVisitedTitleIds);
		} else {
			const index = recentlyVisitedTitleIds.findIndex(titleId => titleId === visitedTitleId);
			const title = recentlyVisitedTitleIds[index];
			recentlyVisitedTitleIds.splice(0, 0, title);
			recentlyVisitedTitleIds.splice(index + 1, 1);
		}

		// limit stored search terms to 10 to avoid store explosion
		state.searches.recentlyVisitedTitleIds = recentlyVisitedTitleIds.slice(0, RECENT_SEARCH_TERMS_COUNT);
	},
	CLEAR_SEARCH_HISTORY: state => {
		state.searches.recentSearchTerms = [];
		state.searches.recentlyVisitedTitleIds = [];
	},
	SET_IS_PREMIUM: (state, value: boolean) => {
		state.isPremium = value;
	},
	SET_SUBSCRIPTION_INFO: (state, value: SubscriptionInfo) => {
		state.subscriptionInfo = value;
	},
	SET_HAS_CANCELLED_PREMIUM: (state, value: boolean) => {
		state.hasCancelledPremium = value;
	},
	SET_PREMIUM_PURCHASE_FLOW_STATE: (state, value: premiumPurchaseFlowState | null) => {
		state.premiumPurchaseFlowState = value;
	},
	SET_TIME_ZONE: (state, value: string) => {
		Vue.set(state, 'timeZone', value);
	},
	SET_HIDE_SEEN_DISLIKE_TITLES: (state, value: boolean) => {
		state.hideSeenDislikedTitles = value;
	},
	SET_IS_VERIFICATION_EMAIL_SENT: (state, isVerificationEmailSent: boolean) => {
		state.isVerificationEmailSent = isVerificationEmailSent;
	},
	SET_LAST_TIME_MOBILE_APP_BANNER_WAS_CLOSED_TS: (state, timeStamp: number) => {
		state.lastTimeMobileAppBannerWasClosedTS = timeStamp;
	},
	SET_LAST_TIME_LIST_ONBOARDING_WAS_SHOWN_TS: (state, timeStamp: number) => {
		state.lastTimeListsOnboardingWasShownTS = timeStamp;
	},
	SET_WEB_BRAZE_ENABLED: (state, isWebBrazeEnabled: boolean) => {
		state.webBrazeEnabled = isWebBrazeEnabled;
	},
	SET_USERCENTRICS_DPS: (state, dataProcessingServices: { id: string; name: string }[]) => {
		state.usercentricsDPS = dataProcessingServices;
	},
	SET_USER_FIRST_VISIT: (state, value: boolean) => {
		state.isUserFirstVisit = value;
	},
	SET_PERMANENT_AUDIENCES_SUBGENRES(state, value) {
		state.permanentAudiencesSubgenres = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
