import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import type { DiscoveryModule } from '@/@types/graphql-types';
import type { Module as ModuleInterface } from '@/interfaces/modules';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { SnowplowModuleContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { GetTitleContextArguments } from '@/helpers/tracking/tracking-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import type { Offer } from '@/@types/graphql-types';

const INITIAL_STATE = {
	permanentAudiences: [] as string[],
	moduleContext: null as SnowplowModuleContext | null,
	titleContextArguments: null as GetTitleContextArguments | null,
	sponsoredAd: null as SponsoredAdFragment | null,
	promotedBuyboxOffers: [] as Offer[],
	lastVisitedTitleId: '' as string,
	lastVisitedTitleName: '' as string,
	lastVisitedTitleDetails: { titleId: '', titleName: '' } as LastVistedTitleDetails,
};

export type TitleDetailState = typeof INITIAL_STATE;

export type LastVistedTitleDetails = { titleId: string; titleName: string };

const state = () => {
	return {
		...INITIAL_STATE,
	};
};

type State = ReturnType<typeof state>;

const getters: GetterTree<State, any> = {
	sponsoredAd(state): SponsoredAdFragment | null {
		return state.sponsoredAd;
	},

	refferalModuleContext(state): SnowplowModuleContext | null {
		return state.moduleContext;
	},

	titleContext(state): GetTitleContextArguments | null {
		return state.titleContextArguments;
	},
	getPermanentAudiences(state): string[] {
		return state.permanentAudiences;
	},

	promotedBuyboxOffers(state): Offer[] {
		return state.promotedBuyboxOffers;
	},

	lastVisitedTitleId(state): string {
		return state.lastVisitedTitleDetails.titleId;
	},
	lastVisitedTitleName(state): string {
		return state.lastVisitedTitleDetails.titleName;
	},
};

const actions: ActionTree<State, any> = {
	setSponsoredRecommendation({ commit }, sponsoredAd: SponsoredAdFragment | null) {
		commit('SET_SPONSORED_RECOMMENDATION', sponsoredAd);
	},
	setModuleContext({ commit }, { module, moduleIndex }: { module: ModuleInterface; moduleIndex: number }) {
		commit('SET_MODULE_CONTEXT', { module, moduleIndex });
	},
	clearModuleContext({ commit }) {
		commit('CLEAR_MODULE_CONTEXT');
	},
	setTitleContext({ commit }, payload: GetTitleContextArguments) {
		commit('SET_TITLE_CONTEXT', payload);
	},
	clearTitleContext({ commit }) {
		commit('CLEAR_TITLE_CONTEXT');
	},
	setPermanentAudiences({ commit }, audiences: string[]) {
		commit('SET_PERMANENT_AUDIENCES', audiences);
		TrackingHelper.refreshPermanentAudiences(audiences);
	},
	resetPermanentAudiences({ commit }) {
		commit('SET_PERMANENT_AUDIENCES', []);
	},
	setLastVisitedTitleDetails({ commit }, lastVisitedTitleDetails: LastVistedTitleDetails) {
		commit('SET_LAST_VISITED_TITLE_DETAILS', lastVisitedTitleDetails);
	},
};

const mutations: MutationTree<State> = {
	SET_SPONSORED_RECOMMENDATION(state, sponsoredAd: SponsoredAdFragment | null) {
		state.sponsoredAd = sponsoredAd;
	},
	SET_MODULE_CONTEXT(state, { module, moduleIndex }: { module: DiscoveryModule; moduleIndex: number }) {
		state.moduleContext = new SnowplowModuleContext(
			module?.id ?? '',
			moduleIndex ?? -1,
			module.template.technicalName,
			module.discoverySessionId ?? '',
			module.template?.anchor ?? undefined
		);
	},
	CLEAR_MODULE_CONTEXT(state) {
		state.moduleContext = null;
	},
	SET_TITLE_CONTEXT(state, titleContextArguments: GetTitleContextArguments) {
		state.titleContextArguments = titleContextArguments;
	},
	CLEAR_TITLE_CONTEXT(state) {
		state.titleContextArguments = null;
	},
	SET_PERMANENT_AUDIENCES(state, value) {
		state.permanentAudiences = value;
	},
	SET_PROMOTED_BUYBOX_OFFERS(state, promotedOffers: Offer[]) {
		state.promotedBuyboxOffers = promotedOffers;
	},
	SET_LAST_VISITED_TITLE_DETAILS(state, lastVisitedTitleDetails: LastVistedTitleDetails) {
		state.lastVisitedTitleDetails = lastVisitedTitleDetails;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
