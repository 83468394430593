import { computed, InjectionKey, provide } from 'vue';

import { SponsoredRecommendationsInput, SraPlacement } from '@/@types/graphql-types';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { ObjectType, SraFormat } from '@/@types/graphql-types';
import { Platform } from '@/@types/graphql-types';
import { TrackingAppId, ApplicationContext } from '@/helpers/tracking/tracking-helper';
import { useLanguageStore } from '@/helpers/composables/useStores';
import { getSrDebuggerInfo } from '@/helpers/sponsoredrec-debugging-tools';

// SR_CALL_TO_ACTION_EXP
import { remoteTriggerSrCallToActionExp } from '@/components/experiments/SRCallToActionExp';

interface UseSponsoredRecOptions {
	pageType: string;
	placement: SraPlacement;
	testingMode?: boolean;
	alwaysReturnBidID?: boolean;
}

export const SR_PROVIDER_KEY = Symbol() as InjectionKey<SraPlacement>;

export const supportedFormats = [SraFormat.Image, SraFormat.Video];
export const supportedObjectTypes = [
	ObjectType.Movie,
	ObjectType.Show,
	ObjectType.GenericTitleList,
	ObjectType.ShowSeason,
];

/** Create a record of every campaign based upon it's placement */
const recordSRPlacement = new Map<SraPlacement, SponsoredAdFragment>();

export function useSponsoredRec({
	pageType,
	placement,
	testingMode = false,
	alwaysReturnBidID = true,
}: UseSponsoredRecOptions) {
	const { language, country } = useLanguageStore();
	provide(SR_PROVIDER_KEY, placement);

	// Get the debugging information to override campaign settings
	const srDebuggerInfo = getSrDebuggerInfo();

	const allowSponsoredRecommendations = computed<SponsoredRecommendationsInput>(() => ({
		// Campaign specific information
		pageType,
		placement,

		// Default settings
		language: language.value,
		country: country.value,
		applicationContext: ApplicationContext, // now with appContext
		appId: TrackingAppId,
		platform: Platform.Web,

		// Have every SR Placement supoort every format
		supportedFormats,
		supportedObjectTypes,
		alwaysReturnBidID,

		// Debugging information
		geoCountry: srDebuggerInfo?.geoCountry,
		testingModeCampaignName: srDebuggerInfo?.campaignName,
		testingModeForceHoldoutGroup: !!srDebuggerInfo?.holdoutGroup,
		testingMode: srDebuggerInfo?.testingMode || testingMode,
	}));

	return {
		/** Input values to call Sponsored Recommmendation */
		allowSponsoredRecommendations,

		/** Save the campaign and it's placement */
		saveSrPlacement: (sponsoredAd: SponsoredAdFragment | undefined) => {
			if (!sponsoredAd) return;
			recordSRPlacement.set(placement, sponsoredAd);

			// SR_CALL_TO_ACTION_EXP
			remoteTriggerSrCallToActionExp(sponsoredAd);
		},
	};
}

/**
 * Return the SR campaign based upon the placement of the campaign
 *
 * @param placement Placement of the campaign
 * @returns SR campaign
 */
export const getSrPlacement = (placement: SraPlacement): SponsoredAdFragment | undefined => {
	return recordSRPlacement.get(placement);
};

/**
 * Get the SR campaign and it's placement based upon the Bid ID
 *
 * @param bidId {string} The unique bid ID for that instance of the SR campaign
 * @returns placement and SR campaign
 */
export const getSrFromBidId = (
	bidId: string
): { placement: SraPlacement | undefined; srFragment: SponsoredAdFragment | undefined } => {
	let placement: SraPlacement | undefined;
	let srFragment: SponsoredAdFragment | undefined;
	recordSRPlacement.forEach((value, key) => {
		if (bidId === value.bidId) {
			placement = key;
			srFragment = value;
		}
	});
	return { placement, srFragment };
};
