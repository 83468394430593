import Vue from 'vue';
import { debugStorage } from '@/services/DebugStorage';
import { ReadyType } from '@/stores/ready.store';
import BuildConfig from '../../env/build-config.json';
import type { SeverityLevel } from '@sentry/browser';

type WebappEnvironments = 'prod' | 'stage' | 'appdev' | 'local';

// JW IDs for which we want to capture errors 100% of the time
const jwIds = [
	'wa8mzs4JEeiMUQpY1-1-Eg', // Christoph Hoyer
];

/** Init Config */
let dsn = JW_CONFIG.SENTRY_DSN;
if (BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
	dsn = JW_CONFIG.SENTRY_DSN_SSR_CLIENT;
}

let release = `jw-app:spa@${BuildConfig.commitShortHash}`;
if (BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
	release = `jw-app:ssr-client@${BuildConfig.commitShortHash}`;
}

const getEnvironment = (): WebappEnvironments => {
	const hostname = location.hostname;
	return hostname.includes('justwatch.com')
		? 'prod'
		: hostname.includes('dev.moviecycle.com')
		? 'appdev'
		: hostname.includes('moviecycle.com')
		? 'stage'
		: 'local';
};

const getSampleRate = (sentryEnvironment: WebappEnvironments): number => {
	if (debugStorage.getState()) {
		// If user is in debug mode - we send full data.
		return 1.0;
	}
	if (sentryEnvironment === 'prod' && BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
		return 0.1;
	}
	if (sentryEnvironment === 'prod' && BUILD_CONFIG.globals.BUILD_TARGET === 'SPA') {
		return 0.1;
	}
	return 1.0;
};
/** Init Config */

const setupSentry = async () => {
	if (!dsn) return false;

	const environment = getEnvironment();
	const sampleRate = getSampleRate(environment);

	try {
		const { BrowserTracing, defaultIntegrations, init, setUser } = await import('@sentry/vue');
		const { CaptureConsole } = await import('@sentry/integrations');

		const filteredIntegrations = defaultIntegrations.filter(
			integration => !['InboundFilters', 'LinkedErrors'].includes(integration.name)
		);

		const integrations = [
			new BrowserTracing({
				tracingOrigins: ['localhost', /^\/[^/]/],
			}),
			new CaptureConsole({ levels: ['error'] }),
			...filteredIntegrations,
		];

		init({
			Vue,
			dsn,
			environment,
			enabled: process.env.NODE_ENV !== 'development',
			// @ts-ignore
			integrations,
			maxValueLength: 10000,
			release,
			tracesSampleRate: sampleRate,

			// EVENT SAMPLING
			// We set 100% sample rate here to then do actual sampling in `beforeSend`
			sampleRate: 1,
			beforeSend(event) {
				if (jwIds.includes(event?.user?.id as string)) return event;

				return Math.random() <= sampleRate ? event : null;
			},
		});

		// Sentry User ID
		Vue.$jw.ready?.waitFor(ReadyType.JW_ID).then(async () => {
			const { getVm } = await import('@/helpers/vm-helper');
			const vm = getVm();
			// there is a possibility that we haven't initiated the vue app yet and that makes getVm() return undefined.
			// this is mostly due to failing requests during bootstrap (geolocation, transaltion, ...; for whatever reason) that throw errors.
			setUser({ id: vm?.$store.state.user.jwId });
		});
	} catch (error) {
		close();
		throw new Error(error as any);
	}
};

/** Sentry Init State Machine */
enum STEPS {
	COLD = 0,
	INITIALIZED = 1,
	FAILED = 2,
}
let INIT_STEP: 0 | 1 | 2 = STEPS.COLD;
const initSentry = () => {
	if (!process.client) return false;
	switch (INIT_STEP) {
		case STEPS.COLD:
			try {
				setupSentry();
				INIT_STEP = STEPS.INITIALIZED;
				return true;
			} catch {
				INIT_STEP = STEPS.FAILED;
				return false;
			}
		case STEPS.INITIALIZED:
			return true;
		case STEPS.FAILED:
			return false;
	}
};

/** Sentry Init State Machine */

const captureMessageForSentry = async (
	name: string,
	payload: { [key: string]: any; error?: Error } = {},
	level: SeverityLevel = 'info'
) => {
	if ((process.env.NODE_ENV === 'development' || JW_CONFIG.DOMAIN === 'moviecycle.com') && payload.error) {
		console.error(`${name}\n`, `${payload.where ?? ''}\n`, payload.error);
	}
	if (!initSentry()) return false;

	const { withScope, captureException, captureMessage } = await import('@sentry/vue');

	withScope(scope => {
		const error = payload['error'];
		const asException = level === 'error' && error;
		if (asException) {
			delete payload['error'];
		}

		scope.setExtra('1. version', process.env.VERSION);
		scope.setExtra('2. message', payload);
		scope.setLevel(level);

		if (asException) {
			if (error!.message) {
				error!.message = [name, error!.message].join(' ');
			}
			captureException(error);
		} else {
			captureMessage(name);
		}
	});
};

export { captureMessageForSentry, initSentry };
