import { DataProcessingService } from '@/helpers/usercentrics-helper';
import { SnowplowContext } from './snowplow-contexts';
import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';

declare global {
	interface Window {
		fbq: any;
		_fbq: any;
	}
}

export class TrackingProviderFacebookPixel extends TrackingProvider {
	dataProcessingService = DataProcessingService.FACEBOOK_PIXEL;

	constructor(private config: { pixelId: string; doNotTrack: boolean }) {
		super();
	}

	initialize() {
		return new Promise<void>((resolve, reject) => {
			if (!this.config.doNotTrack) {
				if (window.fbq) return;
				const n: any = (window.fbq = function () {
					n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
				});
				if (!window._fbq) window._fbq = n;
				n.push = n;
				n.loaded = !0;
				n.version = '2.0';
				n.queue = [];
				const t = document.createElement('script');
				t.async = !0;
				t.src = 'https://connect.facebook.net/en_US/fbevents.js';
				const s = document.getElementsByTagName('script')[0];
				s.parentNode!.insertBefore(t, s);
				window.fbq('init', this.config.pixelId);
			}
			resolve();
		});
	}

	async trackPage() {
		if (this.config.doNotTrack) return;
		window.fbq('track', 'PageView');
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {
		if (this.config.doNotTrack || permanent_audiences.length === 0) return;

		window.fbq('trackCustom', 'PermanentAudience', {
			content_category: 'permanent_audience',
			content_ids: permanent_audiences,
		});
	}

	refreshPermanentAudiencesSubgenres(permanent_audiences: string[] = []) {
		if (this.config.doNotTrack || permanent_audiences.length === 0) return;

		window.fbq('trackCustom', 'PermanentAudienceSubgenreTest', {
			content_category: 'permanent_audience',
			content_ids: permanent_audiences,
		});
	}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {}
}
