
import { ModalHelper } from '@/helpers/modal-helper';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AccountModal, SettingsModal } from '@/lazy-load-components';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';

const User = namespace('user');

@Component({ name: 'LoginMenuButton' })
export default class LoginMenuButton extends Vue {
	loading: boolean = false;

	@User.Getter isLoggedIn: boolean;
	@User.State loginProgress: boolean;

	async handleLoginClick() {
		this.loading = true;

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'user_account' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		if (this.isLoggedIn) {
			await ModalHelper.openModal(SettingsModal, { closable: true });
		} else {
			await ModalHelper.openModal(
				AccountModal,
				{ closable: true },
				{ cssClass: 'account-modal' },
				'LoginMenuButton'
			);
		}

		this.loading = false;
	}
}
